/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'w5Hn7wiLY4oNH2a5xn7yKE.png': { uri: '/images/w5Hn7wiLY4oNH2a5xn7yKE.png' },
'egYuggA8Ruvgdedb7368M6.jpg': { uri: '/images/egYuggA8Ruvgdedb7368M6.jpg' },
'xiDdXAfW9yXtPQQFMkzS4L.png': { uri: '/images/xiDdXAfW9yXtPQQFMkzS4L.png' },
'3TTr2mECXpv68AVUWb79Md.png': { uri: '/images/3TTr2mECXpv68AVUWb79Md.png' },
'rSGo3fLyrRx6sMWmFLMTcW.jpg': { uri: '/images/rSGo3fLyrRx6sMWmFLMTcW.jpg' },
'feudJkqXtkbkW9eVFmU5D2.png': { uri: '/images/feudJkqXtkbkW9eVFmU5D2.png' },
'jNVXGfsw1NKHuu3d6C8A7R.jpg': { uri: '/images/jNVXGfsw1NKHuu3d6C8A7R.jpg' }
}

export default imageStaticSourcesByFileName
